module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Нашы Грошы","short_name":"Нашы Грошы","id":"/","start_url":"/","background_color":"#ffffff","theme_color":"#31669c","display":"standalone","crossOrigin":"use-credentials","icons":[{"src":"favicon/favicon-150x150.svg","sizes":"150x150","type":"image/svg","purpose":"any"},{"src":"favicon/1024x1024.png","sizes":"1024x1024","type":"image/png","purpose":"any"},{"src":"favicon/1024x1024.png","sizes":"1024x1024","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
